import React from "react";
import { graphql } from 'gatsby'

import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Portfolio from "../components/portfolio";

export default function Home({ path, data: { cms: { portfolio_companies, seo, entry }} }) {
  return (
    <Layout theme="dark">
      <SEO
        title={seo.title}
        description={seo.og_description}
        meta={[
          {
            "property": "og:image",
            "content": "/images/og-updated.png"
          }
        ]}
      />
      <Hero heading={entry.headline} body={''} />
      <Portfolio gridItems={portfolio_companies.data} />
    </Layout>
  );
}


export const query = graphql`
query companies {
  cms {
    portfolio_companies: entries(collection: "companies", sort: "title") {
      data {
        id
        slug
        ... on cms_Entry_Companies_Companies {
          id
          title
          founder_label
          exitedBy
          hasExited
          founders
          company_url
          description
          logo {
            ... on cms_Asset_Assets {
              id
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            id
            path
          }
        }
      }
    }
    entry(id: "6a713957-023d-4405-858b-bf051021bfdb") {
      ... on cms_Entry_Pages_PageGeneric {
        id
        title
        headline
      }
    }
    seo: SeoMeta(page_id: "6a713957-023d-4405-858b-bf051021bfdb") {
      og_description
      og_title
      title
    }
  }
}

`